<template>
  <div class="report-attachment" ref="equipmentStatistics">
    <div class="form-area" ref="form">
      <analysisForm :showMultDay="true" @getFormVal="onSearch" :chooseToday="true">        
        <el-button
          type="primary"
          size="small"
          :disabled="tableData[0].map==null"
          :loading="downLoadStatus"
          @click="exportData"
          >导出</el-button
        >
      </analysisForm>
      <!-- 重新写一个组件--针对新的方案（根据终端类别来做数据筛选） -->
    </div>
    <el-table
    ref="tableName"
      :data="tableData"
      stripe
      :height="tableHeight"
      style="width: 100%"
    >
      <el-table-column
        prop="companyName"
        label="所属企业"
        width="200px"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="cph"
        label="车牌号"
        width="110px"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column prop="deviceNo" label="终端号" width="160"></el-table-column>
      <el-table-column prop="total" label="应有附件数" ></el-table-column>
      <el-table-column prop="actualTotal" label="实际附件数"></el-table-column>
      <el-table-column prop="percentage" label="完整率">
        <template slot-scope="scope">
          <span v-if="tableData[0].map">
          {{ scope.row.percentage + "%" }}
          </span></template>
      </el-table-column>
       <el-table-column
        v-for="(obj, key, index) in tableData[0].map"
        :key="index"
        width="120px"
        :label="queryDate(key)"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <div>{{tableData[scope.$index].mapArr[index].label}}
          </div>
        </template>
       </el-table-column>
    </el-table>
    <div ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import analysisForm from "@/components/analysisForm/analysisFormNew.vue";
import { externalList } from "@/api/lib/gps-api.js";
import { getCurent } from "@/common/utils/index";
import { addExcelExport } from "@/api/lib/refreshAlarm.js";
export default {
  name: "driftStatistics",
  components: {
    analysisForm
  },
  data() {
    return {
      tableHeight: 0,
      tableData: [
        {map:null}        
      ],
      form: {
        currentPage: 1,
        pageSize: 10,
        beginTime:'',
        endTime:''
      },
      total: 0,
      exportForm: {},
      downLoadStatus: false,
    };
  },
  computed:{
    queryDate(){
      return function(obj){
        return obj.substring(5,10)
      }
    }
  },
  methods: {    
    
    // 计算表格高度
    computeHeight() {
      let wholeHeight = this.$refs.equipmentStatistics.clientHeight;
      let formHeight = this.$refs.form.clientHeight;
      let paginationHeight = this.$refs.pagination.clientHeight;
      this.tableHeight =wholeHeight - 32 - formHeight - paginationHeight - 10;
    },
    //导出
    exportData() {
      this.downLoadStatus = true;
      delete this.exportForm.currentPage;
      delete this.exportForm.pageSize;
      let data = {
        baseUrl: "report",
        fileName: `报警附件完整率-${getCurent()}`,
        filePath: null,
        fileStatus: 1,
        generateTime: null,
        queryParam: JSON.stringify(this.exportForm),
        queryPath: "/external/export",
      };
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.downLoadStatus = false;
          this.$store.dispatch("setRefreshStatus", true);
        })
        .catch(() => {
          this.downLoadStatus = false;
        });
    },
    // 点击查询
    onSearch(value) {
      this.form.vehicleNos = value.vehicleNos;
      this.form.companyId = value.companyId;
      this.form.beginTime = value.beginTime;
      this.form.endTime = value.endTime;
      this.form.currentPage = 1;
      this.form.pageSize = 10;
      this.getListByField();
    },
    ObjsubString(obj){
      return obj.substring(5,10)
    },
    //获取数据
    getListByField() {
      let data = { ...this.form };
      externalList(data).then((res) => {
        if (res.code === 1000) {
          if(res.data.list){
            res.data.list.map(item=>{
              item.mapArr=[];
              let obj = item.map
              for(let key in obj){
                item.mapArr.push({label:obj[key],time:key})
                // item.mapArr.push(obj[key]);
              }
            })
            this.tableData = res.data.list;
            
            this.total = res.data.total;
            this.$nextTick(() => {
              this.$refs.tableName.doLayout();
            })
          }else{
            this.tableData =[{map:null}];
            this.total = 0;
          }
        } else {
          this.tableData = [{map:null} ];
          this.total = 0;
          this.$message.error(res.msg);
        }
        this.exportForm = { ...data };
      });
    },
    // 当前页码改变
    onCurrentChange(page) {
      this.form.currentPage = page;
      this.getListByField();
    },
    // 当前条数改变
    onSizeChange(size) {
      this.form.pageSize = size;
      this.getListByField();
    }
  },
  created() {
    
  },
  mounted() {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.computeHeight);
  },
};
</script>

<style lang="scss" scoped>
.report-attachment {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
}
.add {
  height: 100%;
  padding: 2vh 3vh;
}

.theme-project-gps {
  .report-attachment {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
.demo-form-inline{
  grid-template-columns: 15% 15% 15% 50%;
  gap: 5px 10px;
}
</style>
